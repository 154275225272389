<template>
    <r-e-dialog :title="title" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer top="10vh"
                width="550px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房租金额">
                    <el-input-number v-model="rentalAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="杂费金额">
                    <el-input-number v-model="otherAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="押金金额">
                    <el-input-number v-model="depositAmount" style="width: 365px;" :precision="2" :step="1" disabled/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款日期" prop="cancelTime">
                    <el-date-picker v-model="formPublish.cancelTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 365px;" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款方式" prop="paymentMethod">
                    <el-select v-model="formPublish.paymentMethod" style="width: 365px;" placeholder="请选择付款方式">
                        <el-option v-for="(item,index) in paymentTypeList" :key="index" :label="item.codeName"
                                   :value="item.code"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="图片" prop="imgs">
                    <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess" @on-remove="handleRemove" />
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import { MessageSuccess } from "@custom/message";
    import { ParseFloatFormat } from "@custom/index";
    import { getCancelAmount, cancelBills } from "@/api/bill";
    export default {
        name: "layer-batch-write-off",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuids: null,
                    paymentMethod: null,
                    cancelTime: null,
                    imgs: null,
                },
                paymentTypeList: [],
                rules: {
                    paymentMethod: [{required: true, message: '请选择付款方式', trigger: 'change'}],
                    cancelTime: [{required: true, message: '请选择付款日期', trigger: 'change'}],
                    imgs: [{required: true, message: '请上传核销图片', trigger: 'blur'}],
                },
                title: '批量核销',
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                imgList: [],
                depositAmount: null,
                amountData: null,
                otherAmount: null,
                rentalAmount: null,
            };
        },
        async created() {
            // 获取用户uuid
            this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
            this.paymentTypeList = this.paymentTypeList.filter(item => item.code !== 500101 && item.code !== 500102); //&& item.code !== 500106 && item.code !== 500107
        },
        components: {
            uploadPictureCard: () => import("@/components/Upload/upload-picture-card"),
        },
        methods: {
            openDialog(data) {
                let that = this;
                let { uuids } = data;
                that.formPublish.uuids = uuids;
                that.getCancelAmount(data);
            },
            clickSubmit() {
                let that = this;
                this.$refs["formPublish"].validate((valid) =>{ if (valid) that.he(this.formPublish)});
            },
            async getCancelAmount(data) {
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                const res = await getCancelAmount(data).finally(() => loading.close());
                const {info: {depositAmount, otherAmount, rentalAmount}} = res;
                this.depositAmount = ParseFloatFormat(depositAmount);
                this.otherAmount = ParseFloatFormat(otherAmount);
                this.rentalAmount = ParseFloatFormat(rentalAmount);
                this.dialogVisible = true;
            },
            he(data) {
                let that = this;
                this.$confirm('此操作将永久核销此账单, 是否继续?', '提示', {type: 'warning'}).then(() => {
                    let { uuids, paymentMethod, cancelTime, imgs } = data;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let transactionCode = Math.random();
                    cancelBills({ uuids, paymentMethod, cancelTime, imgs, transactionCode }).then(res => {
                        MessageSuccess('核销成功');
                        that.clickCancel();
                    }).finally(() => loading.close());
                }).catch(() => {});
            },
            async clickCancel() {
                this.formPublish = { uuids: null, paymentMethod: null, cancelTime: null, imgs: null };
                this.imgList = [];
                this.depositAmount = null;
                this.otherAmount = null;
                this.rentalAmount = null;
                await this.$emit('clickCancel');
                this.dialogVisible = false;
            },

            handleSuccess({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.imgs = this.imgList.join(";");
                this.$refs["formPublish"].validateField("imgs");
            },

            handleRemove({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.imgs = this.imgList.join(";");
                this.$refs["formPublish"].validateField("imgs");
            },

        }
    }
</script>

<style scoped>

</style>